.boxMain {
    padding: 32px;
    border: 1px solid #ccc;
    border-radius: 10px;
    /* background-color: #f0f6f9;  */
    // background-color: #ffffff;
    background-color: #ffffffe8;
    box-shadow: 0 0 5px 5px rgba(0,0,0,0.03);
    margin: 5px 5px 20px 0px;
    position: relative;
}