.tex-transform-lowercase {
    text-transform: lowercase;
}

.tex-transform-uper {
    text-transform: uppercase;
}

.text-tile-tabs {
    font-size: 18px;
    line-height: 25.4px;
    font-weight: 600;
    color: #154398;
}

.text-italic {
    font-style: italic;
    transform: rotate(-2deg);
}
.text-italic-opacity {
    font-style: italic;
    transform: rotate(-2deg);
    opacity: 0.5
}