.buttonWrapper {
  border-radius: 6px;
  display: flex !important;
  align-items: center;
  font-weight: 500;
  line-height: 10px;
  justify-content: center;
  height: 40px !important;

  .name {
    font-weight: 700;
    line-height: 14px;
  }

  .icon {
    svg {
      height: 18px;
      width: 18px;
    }
  }

  .iconLarge svg {
    height: 18px;
    width: 18px;
  }
}

.disable-btn {
  border: 2px solid #8e8e93 !important;
  span {
    color: #8e8e93 !important;
    svg line {
      stroke: #8e8e93 !important;
    }
    svg g path {
      fill: #8e8e93 !important;
    }
  }
}

.disable {
  box-shadow: none !important;
  cursor: not-allowed;

  &:not(.icon svg path) {
    opacity: 0.7;
  }

  .icon svg path {
    &[fill] {
      fill: #ced4da;
    }

    &[stroke] {
      stroke: #ced4da;
    }
  }
}

@mixin btnType(
  $backgroundColor,
  $borderColor,
  $color,
  $hoverBackground,
  $hoverBorderColor,
  $hoverColor
) {
  background: $backgroundColor !important;
  border-color: $borderColor !important;
  color: $color !important;

  &:hover,
  &:focus {
    // opacity: 0.8;
    background: $hoverBackground !important;
    border-color: $hoverBorderColor !important;
    color: $hoverColor !important;
    span {
      color: $hoverColor !important;
    }
  }
  span {
    color: $color !important;
  }
}

.linear {
  @include btnType(
    linear-gradient(90deg, #154398 0%, #ed1117 100%),
    #ffffff,
    #ffffff,
    linear-gradient(90deg, #154398 0%, #ed1117 100%),
    #ffffff,
    #ffffff
  );
}
.greenLeaves {
  @include btnType(#7fca27, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff);
  &:hover {
    background: #9acf1c !important;
    color: #ffffff !important;

    div span {
      // color: #154398 !important;
    }

    svg path {
      // fill: #154398;
      // stroke: #154398;
    }
  }
}

// .primary {
//   @include btnType(#01638D, #01638D, #ffffff, #01638D, #81D9FF, #ffffff);

//   svg path {
//     stroke: #ffffff;
//     fill: #ffffff;
//   }
//   & {
//     border: 2px solid;
//   }
//   &:hover,
//   &:focus {
//     border: 2px solid;
//     // svg path {
//     //   stroke: #01638D;
//     // }
//   }
// }
// .primary {
//   @include btnType(linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb),
//     #af40ff, #ffffff, #5b42f3, #5b42f3, #ffffff);

//   svg path {
//     stroke: #ffffff;
//     fill: #ffffff;
//   }
//   & {
//     border: 1px solid;
//   }
//   &:hover,
//   &:focus {
//     border: 1px solid;
//   }
// }
.primary {
  @include btnType(#a6078a, #a6078a, #ffffff, #c013a0, #a6078a, #ffffff);

  svg path {
    stroke: #ffffff;
    fill: #ffffff;
  }
  & {
    border: 1px solid;
  }
  &:hover,
  &:focus {
    border: 1px solid;
  }
}
.primary:active {
  background: #2e5fb9 !important; /* Màu nền mới khi click */
  color: #0a3587 !important; /* Màu chữ mới khi click */
}
.primary-no-hover {
  @include btnType(#01638d, #01638d, #ffffff, #01638d, #01638d, #ffffff);
}
.disable-style {
  @include btnType(
    rgba(242, 242, 242, 0.9),
    rgba(142, 142, 147, 1),
    rgba(142, 142, 147, 1),
    rgba(242, 242, 242, 0.9),
    rgba(142, 142, 147, 1),
    rgba(142, 142, 147, 1)
  );

  // svg path {
  //   stroke: rgba(142, 142, 147, 1);
  // }
  svg line {
    stroke: rgb(128, 128, 133) !important;
  }
  svg g path {
    fill: rgb(128, 128, 133) !important;
    stroke: rgb(128, 128, 133) !important;
  }
}

.yellow {
  @include btnType(#ffd800, #ffd800, #172b4d, #ffd800, #ffd800, #172b4d);
}

.secondary {
  @include btnType(#b6bcc4, #b6bcc4, #ffffff, #b6bcc4, #b6bcc4, #ffffff);
  font-weight: 700 !important;
}

.closeVND {
  @include btnType(#777779, #777779, #ffffff, #777779, #777779, #ffffff);
}

.green {
  @include btnType(#ffffff, #0d99ff, #0d99ff, #ffffff, #0d99ff, #0d99ff);
}

.third {
  @include btnType(
    #ffffff,
    #c341a0,
    #c341a0,
    rgba(1, 99, 141, 0.08),
    #c341a0,
    #c341a0
  );
}
.red-style {
  @include btnType(
    #ffffff,
    #d63939,
    #d63939,
    rgba(206, 49, 53, 0.08),
    #d63939,
    #d63939
  );
}
.green-style {
  @include btnType(
    #ffffff,
    #0d9d57,
    #0d9d57,
    rgba(13, 157, 87, 0.08),
    #0d9d57,
    #0d9d57
  );
}
.red-st {
  @include btnType(#ffffff, #d63939, #d63939, #abb2b8, #abb2b8, #ffffff);

  &:hover,
  &:focus {
    svg path {
      stroke: white;
    }
  }
}
.gray-style {
  @include btnType(#f1f3f5, #f1f3f5, #3e4554, #cccccc, #cccccc, #3e4554);
}

.third-st {
  @include btnType(#ffffff, #154398, #154398, #0e8cf1, #0e8cf1, #ffffff);

  &:hover,
  &:focus {
    svg path {
      stroke: white;
    }
  }
}

.fourth {
  @include btnType(#2c2c35, #c4c4c4, #c4c4c4, #2c2c35, #c4c4c4, #c4c4c4);
}

.fifth {
  @include btnType(#ffffff, #8b8a92, #8b8a92, #ffffff, #8b8a92, #8b8a92);
}

.orange {
  @include btnType(#f7941e, #f7941e, #ffffff, #f7941e, #f7941e, #ffffff);
}

.back {
  @include btnType(#f0f0f0, #505050, #505050, #f0f0f0, #505050, #505050);
}

// .gray {
//   @include btnType(#f2f2f2, #f2f2f2, #070707, #f2f2f2, #154398, #154398);

//   .icon svg {
//     height: 24px;
//     width : 24px;
//   }

//   height: 40px !important;
// }

.primaryFilter {
  @include btnType(#0d99ff, #0d99ff, #ffffff, #0d99ff, #0d99ff, #ffffff);

  .icon svg {
    height: 24px;
    width: 24px;
  }

  height: 40px !important;
  width: 40px !important;
}

.dark {
  @include btnType(#757575, #757575, #ffffff, #757575, #757575, #ffffff);
}

.primary-sm {
  @include btnType(#d3f7ff, #d3f7ff, #0d99ff, #d3f7ff, #d3f7ff, #0d99ff);
}

.danger {
  @include btnType(#ffffff, #e50500, #e50500, #e50500, #e50500, #ffffff);

  &:hover {
    background: #e50500 !important;
    color: #fff !important;

    div span {
      color: #fff !important;
    }

    svg path {
      fill: #154398;
      stroke: #fff;
    }

    svg path {
      fill: white;
    }
  }
}

.red {
  @include btnType(#e50500, #ffffff, #ffffff, #ffffff, #e50500, #e50500);

  &:hover {
    svg path {
      fill: e50500;
    }
  }
}

.svg-24 {
  .icon svg {
    height: 24px;
    width: 24px;
  }
}

.svg-18 {
  border-radius: 50% !important;
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
  height: 32px !important;
  width: 32px !important;
  min-width: 32px !important;
  padding-left: 4px;

  .icon svg {
    height: 18px;
    width: 18px;
  }

  &:hover {
    background: #cff4e1 !important;
  }

  &.zoomIn svg {
    animation-name: zoomIn;
    animation-duration: 0.5s;
  }
}

.btn-circle {
  border-radius: 50% !important;
  border: none !important;
  background-color: white;
  box-shadow: none !important;
  height: 30px !important;
  width: 30px !important;
  box-shadow: 0px 2px 5px rgba(187, 199, 208, 0.5);
  min-width: 20px !important;

  .icon svg {
    height: 20px;
    width: 20px;
  }

  // &:hover {
  //   background: #cff4e1 !important;
  // }
  &.zoomIn svg {
    animation-name: zoomIn;
    animation-duration: 0.5s;
  }
}

.minWidth {
  min-width: 84px;
}

@keyframes zoomIn {
  from {
    width: 0px;
    height: 0px;
  }
}
.btn-circleCustom {
  border-radius: 50% !important;
  border: none !important;
  background-color: white;
  box-shadow: none !important;
  height: 30px !important;
  width: 30px !important;
  box-shadow: 0px 2px 5px rgba(187, 199, 208, 0.5);
  min-width: 20px !important;
  // &:hover svg path {
  //   fill: red;

  // }
  // &:hover svg path {
  //   stroke: red;
  // }
  .icon svg {
    height: 20px;
    width: 20px;
  }
  &:hover {
    background: #cff4e1 !important;
  }

  // &:hover {
  //   background: #cff4e1 !important;
  // }
  &.zoomIn svg {
    animation-name: zoomIn;
    animation-duration: 0.5s;
  }
}
